var getSummary = function() {
  var d = new Date();
  var date_string = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/');
  return $.ajax({
    url: '/api-yoa/shifts/summary',
    type: 'get',
    data: {
      venue: document.location.pathname.split('/')[2],
      start_date: date_string
    },
  });
};

var list_item_style = {
  borderBottom: '1px dotted #ccc',
  fontSize: '14px'
};

var ShiftSummary = React.createClass({
  getInitialState: function() {
    return {
      'active': [],
      'special_dates': [],
      'custom_schedules': []
    };
  },
  componentDidMount: function() {
    var self = this;
    console.log('Mounting!');
    getSummary()
      .done(function(response) {
        if (response.status === 200) {
          self.setState({
            'active': response.data.active,
            'special_dates': response.data.special_dates,
            'custom_schedules': response.data.custom_schedules
          });
        } else {
          console.log('User Error getting shift summary');
        }
      })
      .fail(function() {
        console.log('Error getting shift summary');
      });
  },
  render: function() {
    if (this.state.special_dates.length) {
      var special_date_section = <ShiftSpecialDatesSummary data={this.state.special_dates} />
    } else {
      var special_date_section = null;
    }
    return (
        <div className="shift-summaries">
          <ShiftSummaryHeader />
          <ShiftActiveSummary data={this.state.active} />
          {special_date_section}
        </div>
    );
  }
});

/**
 * Just a space to place other options and a link to the calendar page
 */
ShiftSummaryHeader = React.createClass({
  goToCalendar: function() {
    var path = window.location.pathname.split('/').slice(0,-1).join('/');
    window.location = window.location.origin + path;
  },
  render: function() {
    return (
       <div style={{
            borderTop: '1px solid lightgrey',
            borderBottom: '1px solid lightgrey',
            overflow: 'hidden',
            color: '#D8D9D9',
            fontFamily: 'Roboto'
        }}>
        <div className="layout"
             style={{float: 'left',
                    backgroundImage: 'url(/static/dev/images/list-icon.png)',
                    width: 16,
                    height: 16,
                    backgroundSize: '100% 100%',
                    margin: 7
                }}>
        </div>
        <div className="layout"
             style={{borderLeft: '1px solid', height: 23, float: 'left', margin: '3px 0px'}}
         />
        <div className="layout"
             onClick={this.goToCalendar}
             style={{float: 'left',
                    backgroundImage: 'url(/static/dev/images/icons/icon-grid.png)',
                    width: 16,
                    height: 16,
                    backgroundSize: '100% 100%',
                    margin: 7,
                    cursor: 'pointer'
                }}
        >
        </div>
      </div>
    );
  }
});

/**
 * Model for table of shifts
 */
ShiftActiveSummary = React.createClass({
  getDefaultProps: function() {
    return {
      data: [],
    };
  },
  getListItems: function() {
    return this.props.data.map(function(shift_el){
      return <ShiftEntry data={shift_el} />;
    });
  },
  render: function() {
    var shifts = this.getListItems();
    return (
      <div className="shift-summary">
        <h1>Shifts</h1>
        <div className="standard-list">
          <div className="table-header">
                <div className="col col-client">name</div>
                <div className="col col-client">times</div>
                <div className="col col-client">date</div>
                <div className="col col-venuenote">days</div>
                <div className="col col-client">status</div>
          </div>
          {shifts}
        </div>
      </div>
    );
  }
});

/**
 * React model for table entries in the shift summary
 */
ShiftEntry = React.createClass({
  getDefaultProps: function() {
    return {
      data: {}
    };
  },
  getTimesDisplay: function() {
    return this.props.data.start_time +
      ' - ' +
      this.props.data.end_time;
  },
  getDateDisplay: function() {
    var end = 'Indefinitely';
    if (this.props.data.end_date !== null) {
      end = this.props.data.end_date;
    }
    return this.props.data.start_date + ' - ' + end;
  },
  getDaysDisplay: function() {
    var days = this.props.data.days;
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].filter(
      function(d, idx) {
        return days[idx];
      }
    ).join(', ');
  },
  getStatusDisplay: function() {
    return this.props.data.status;
  },
  render: function() {
    return (
      <div className="list-block">
        <div className="standard-row" style={list_item_style}>
          <div className="col col-client">{this.props.data.name}</div>
          <div className="col col-client">{this.getTimesDisplay()}</div>
          <div className="col col-client">{this.getDateDisplay()}</div>
          <div className="col col-venuenote">{this.getDaysDisplay()}</div>
          <div className="col col-client">{this.getStatusDisplay()}</div>
        </div>
      </div>
    );
  },
});

ShiftSpecialDatesSummary = React.createClass({
  getDefaultProps: function() {
    return {
      data: [],
    };
  },
  getListItems: function() {
    return this.props.data.map(function(shift_el){
      return <SpecialDateEntry data={shift_el} />;
    });
  },
  render: function() {
    var self = this;
    var special_dates = self.getListItems();
    return (
      <div className="shift-summary">
        <h1>Special Dates</h1>
        <div className="standard-list">
          <div className="table-header">
          </div>
          {special_dates}
        </div>
      </div>
    );
  }
});

SpecialDateEntry = React.createClass({
  getDefaultProps: function() {
    return {
      data: [],
    };
  },
  formatEntry: function(x){
    return x.date_display;
  },
  render: function() {
    var self = this;
    var contents = self.formatEntry(this.props.data);
    return (
      <div className="list-block">
        <div className="standard-row" style={list_item_style}>
          <div className="col col-client">{contents}</div>
        </div>
      </div>
    )
  },
})

$(document).ready(function() {
  React.render(<ShiftSummary />, document.getElementById('content-data'));
});
